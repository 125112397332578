/* Coolors Exported Palette - coolors.co/394648-69995d-cbac88-edb6a3-f8e9e9 */

/* RGB */
$color1: rgba(57, 70, 72, 1);
$color2: #2f7160;
// $color2: rgba(105, 153, 93, 1);
$color3: rgba(203, 172, 136, 1);
$color4: rgba(237, 182, 163, 1);
$color5: rgba(248, 233, 233, 1);

$mobile-max: 767px;
$desktop-min: 768px;

@mixin shadow {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  // background: $color1;
  // color: white;
  // background: linear-gradient(45deg, #0f2027, #203a43, #2c5364);

  // background: linear-gradient(231deg, #2f7160, #52e5bf);
  background: linear-gradient(231deg, #122226, #22353a);
  background-attachment: fixed;
  background-size: cover;
  background-size: 200% 200%;

  animation: AnimationName 30s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 84% 0%;
    }
    50% {
      background-position: 17% 100%;
    }
    100% {
      background-position: 84% 0%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 84% 0%;
    }
    50% {
      background-position: 17% 100%;
    }
    100% {
      background-position: 84% 0%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 84% 0%;
    }
    50% {
      background-position: 17% 100%;
    }
    100% {
      background-position: 84% 0%;
    }
  }
  font-family: Nunito, sans-serif;
  margin: 0;
}

/* Layout */
#body-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  // background: url('images/bg-tokyo.jpg');
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;

  // animation: AnimationName 60s ease infinite;
  // transition: 1s opacity;

  &.show {
    opacity: 1;
  }
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;

  @media screen and (min-width: $desktop-min) {
    margin-left: 250px;
    max-width: 700px;
  }
  @media screen and (min-width: 1200px) {
    margin: 0 auto;
    max-width: 700px;
  }
}
.header {
  height: 100px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 1s height ease;

  #page-about & {
    height: 30vh;
  }

  h1 {
    margin: 32px 0 0 0;
    font-weight: 800;
    transition: 1s margin ease;

    #page-about & {
      margin: 0;
    }
  }
  .ingress {
    font-style: italic;
    opacity: 0;
    overflow: hidden;
    margin: 10px 0 0;
    transition: 1s all;

    #page-about & {
      opacity: 1;
    }
  }
}

.content {
  flex-grow: 1;
  display: flex;

  @media screen and (min-width: $desktop-min) {
    flex-direction: column;
    justify-content: center;
  }
}

.footer {
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color2;
  height: 48px;
  border-radius: 15px 15px 0 0;
  overflow: hidden;

  @media screen and (min-width: $desktop-min) {
    height: auto;
    bottom: auto;
    left: 20px;
    top: 100px;
    width: 210px;
    border-radius: 0 10px 0 10px;
    @include shadow();
  }

  @media screen and (min-width: 1200px) {
    top: 30px;
    left: 30px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;

    @media screen and (min-width: $desktop-min) {
      flex-direction: column;
    }

    li {
      display: inline;
      flex-grow: 1;
      text-align: center;
    }

    a {
      display: block;
      height: 48px;
      line-height: 48px;
      padding: 0 10px;
      color: white;
      text-decoration: none;
      // background: $color3;

      &:hover {
        background: lighten($color2, 5);
      }

      &.active {
        background: lighten($color2, 10);
      }
    }
  }
}
.badge {
  display: none;
  position: fixed;
  opacity: 0;
  @media screen and (min-width: $desktop-min) {
    display: block;
    right: 25px;
    bottom: 25px;
    transition: 1s opacity;
    transition-delay: 1s;
  }

  &.show {
    opacity: 0.5;
  }
}

/* Sections */
.section {
  min-height: 70vh;
  background: white;
  padding: 15px 15px 60px;
  margin: 0 10px;
  border-radius: 10px 10px 0 0;
  flex-grow: 1;

  @media screen and (min-width: $desktop-min) {
    border-radius: 10px;
    margin: 0 20px 30px 0;
    padding: 25px;
    min-height: auto;
    flex-grow: 0;
    @include shadow();
  }

  &--content {
    text-align: center;
  }
}

#skills {
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .skill {
    margin-bottom: 1em;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

#contact {
  .profile-photo {
    border-radius: 50% 50%;
    max-width: 150px;
    @media screen and (min-width: $desktop-min) {
      max-width: 200px;
    }
  }

  .name {
    margin: 10px 0;
    font-size: 2em;
  }

  ul {
    text-align: left;
    // display: inline-block;
    margin: 0 auto 1em;
    list-style: none;
    padding: 0;
    display: inline-block;

  }

  .contact-info {
    span {
      display: inline-block;
      width: 60px;
    }
  }

  .button-list {
    text-align: center;
    max-width: none;
    @media screen and (max-width: $mobile-max) {
      max-width: 280px;
    }

    li {
      display: inline;

      // &:nth-child(3) {
      //   clear: both;
      //   display: inline-block;
      // }
    }

    a {
      display: inline-block;
      border: 1px solid $color2;
      text-decoration: none;
      border-radius: 2px;
      // color: white;
      padding: 5px 10px;
      margin: 0 5px 10px;

      &:hover {
        background: lighten($color2, 10);
        color: white;
      }
    }
  }

}


.tags {
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    // border: 1px solid #ccc;
    background: lighten($color2, 10);
    color: white;
    padding: 3px 7px;
    margin: 0 5px 5px 0;
  }
}

/* Elements */
h2,
h3 {
  margin-top: 0;
  font-weight: normal;
}

h2 {
  color: $color2;
}
h3, h4 {
  color: lighten($color2, 5);
}

a {
  color: $color2;
}

/* Helpers */
.hide {
  display: none;
}
.pull-right {
  float: right;
}
.clearfix {
  &:after {
    content: ' ';
    display: block;
  }
  // overflow: hidden;
}